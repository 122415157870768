import Vue from 'vue';
import { TabsPlugin, FormDatepickerPlugin } from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createI18n, castToVueI18n } from 'vue-i18n-bridge';
import VueI18n from 'vue-i18n';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSimpleAlert from "vue-simple-alert";
import VueCompositionAPI from '@vue/composition-api'
import DatePicker from 'vue2-datepicker';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import 'vue2-datepicker/index.css';
import './assets/styles/loader.css';
import CKEditor from '@ckeditor/ckeditor5-vue2';

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';

// Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)
Vue.use(TabsPlugin);
Vue.use(FormDatepickerPlugin);
// Vue.use(FormCheckboxPlugin);
Vue.use(VueSimpleAlert);
Vue.use(VueCompositionAPI)
Vue.use(DatePicker)
Vue.use(PulseLoader)
Vue.use(CKEditor);


Vue.config.productionTip = false;

// imp ort { BFormCheckboxGroup } from 'bootstrap-vue';
// Vue.component('b-form-checkbox-group', BFormCheckboxGroup);
Vue.use(VueI18n);
Vue.use(VueAxios, axios)

const messages = {
  'en': {
    welcomeMsg: 'Find a doctor, make an appointment, get the consultation online anywhere in the world.',
    joinBtn: 'Join Now',
    aboutUs:'about us',
    aboutUsTitle: 'Doc2You is a global medical digital platform for people who value their health and the health of their loved ones.',
    aboutUsDescription: 'Doc2You gives you the opportunity to learn about new trends in the field of medicine. Doc2You helps you monitor your health, using the knowledge of doctors and pharmacists, as well as familiarize yourself with the best practices in the field of medicine. Doc2You is an independent project whose main value is a person and his health. Do you need a doctor or information to solve a problem? Doc2You will give you the necessary answers and help you. Do you need to expand your medical practice, find new clients, or share your experience with colleagues? Doc2You will help you reveal and strengthen your professionalism. Doc2You is an interesting place for professional communication with colleagues and patients from all over the world. Doc2You gives you a full range of the necessary functionality that will allow you to maximally realize your professional ambitions or satisfy the requests of patients.',
    serviceTitle:"Services we provide",
    whatWeDo:"what we do",
    getInTouch:"get in touch",
    getInTouchTitle:"Get the right health care, book your online appointment",
    getInTouchDescription:"Have a question? We will answer all your questions and help in any even the most difficult cases!",
    ourContacts:"our contacts",
    ourContactsTitle:"Online consultations with qualified doctors",
    whatWeDoFirst:{
      id:1,
      svgSrc: 'assets/what-we-do/Vector.svg',
      imgSrc: 'assets/what-we-do/what-we-do_card-1.png',
      color: '#e8375f',
      title: ' Primary Care 24/7',
      description:
        "An independent online platform that gives patients the opportunity to find doctors and organize consultations with them online, anywhere in the world, at any time.",
    },
    whatWeDoSecond:{
      id:2,
      svgSrc: 'assets/what-we-do/what-we-do_card-block-mask.svg',
      imgSrc: 'assets/what-we-do/what-we-do_card-2.png',
      color: '#0F94BC',
      title: 'EHR Electronic Health Record',
      description:
        "Getting access to your secure electronic medical record, medical history, statements, and prescriptions in your personal account.",
    },
    whatWeDoThird:{
      id:3,
      svgSrc: 'assets/what-we-do/what-we-do_card-block-mask.svg',
      imgSrc: 'assets/what-we-do/what-we-do_card-2.png',
      color: '#0F94BC',
      title: 'Doctor Online',
      description:
      "The platform provides an opportunity for doctors to create their own online medical practice and satisfying patients' requests. And patients to receive a qualified medical consultation on their gadget.",
    },
    addressTitle:"Our address",
    address:"1013 Centre Road Suite 403-B Wilmington, DE 19805",
    emailTitle:"Our email",
    phoneTitle:"Phone number",
    subscribe:"Subscribe to updates for our monthly newsletter",
    send:"Send",
    mainFirst:{
      title:"Find the best doctor",
      description:"We have only the best experienced doctors",
    },
    mainSecond:{
      title:"Health guarantee",
      description:"We guarantee the quality of service",
    },
    mainThird:{
      title:"Get the consultation",
      description:"Online consultation at any time of the day",
    },
    mainFour:{
      title:"Make an appointment",
      description:"Make an appointment with your doctor",
    },
    menu:{
      aboutUs:"About us",
      ourServices:"Our services",
      contacts:"Contacts",
    },
    internalMenu:{
      dashboard:"Dashboard",
      profile:"Profile",
      ehr:"EHR",
      schedule:"Schedule",
      news:"News",
      messages:"Messages",
      settings:"Settings",
      medicalR:"Medical Reports",
      requestsOfD:"Requests of Doctors",
      doctors:"Doctors",
      patients:"Patients",
      wallet:"Wallet",
      services:"Services",
      logOut:"Log Out",
    },
    profilePage: {
      userProfile:"User profile",
      general:"General",
      fullName:"Full name",
      gender:"Gender",
      placeLived:"Places lived",
      speciality:"Speciality",
      contactInfo:"Contact Info",
      phoneNumber:"Phone number",
      approvedPhone:"Approved phone number",
      change:"Change",
      educationWork:"Work & Education",
      work:"Work",
      education:"Education",
      changePass:"Change password",
      oldPass:"Old password",
      newPass:"New",
  },
  ehrPage:{
    medicalReport:"Medical report",
    downloadPdf:"Download PDF",
    consultation:"Consultation",
    consultationDate:"Consultation date",
    doctor:"Doctor",
    specialization:"Specialization",
    meetingType:"Meeting type",
    reportDate:"Report date",
    lastUpdate:"Last update",
    diacrisis:"Diacrisis",
    comorbidities:"Comorbidities",
    previousDiseases:"Previous diseases",
    chronicDiseases:"Chronic diseases",
    complaints:"Complaints", // dodatu
    presentIllness:"Present illness",
    objectivData:"Objective data (optional):",
    impression:"Impression",
    procedure:"Procedure/Check studies",
    pharmaDrugs:"Pharma drugs",
    repeatedCons:"Repeated consultation",
    finish:"Finish",
  },
  shedulePage:{
    patientCard:"Patient card",
    height:"Height",
    weight:"Weight",
    bloodType:"Blood type",
    phone:"Phone",
    cardNumber:"Card Number",
    email:"Email",
    medicalConditions:"Medical Conditions",
    passport:"Passport",
    emergency:"Emergency",
    consultation:"Consultation",
    firstTime:"First time",
  },
  newsPage:{
    news:"News",
    newPost:"New Post",
    myPublication:"My publication",
    comments:"Comments",
    likes:"Likes",
    share:"Share",
    writeComment:"Write your comment",
    discussions:"Discussions",
    popularProfile:"Popular profile",
    viewProfile:"View profile",
  },
  requestOfDPage:{
    welcome:"Welcome",
    welcomeh2:"You have 7 patients remaining today and 4 requests!",
    todays:"Todays",
    seeDetails:"See details",
    profile:"Profile",
    psychiatrist:"Psychiatrist, Therapist",
    allPatients:"All patients this month",
    incomeThisM:"Income this month",
    newAppointments:"New patients Appointment",
    todaysAppointments:"Today's Appointment", 
    appointmentsRequests:"Appointment Requests", //dodatu
    appointmentRequests:"Appointment Requests",
    historyAppointments:"History Appointment",
    consultation:"Consultation",
    firstVisit:"First visit",
    emergency:"Emergency",
    patientDetails:"Patient Details",
    DateOfB:"Date of Birth",
    gender:"Gender",
    weight:"Weight",
    height:"Height",
    lastAppointmentg:"Last Appointment",
    registerDate:"Register Date",
    chat:"Chat",
    ehr:"EHR",
  },
  doctorsPage:{
    add:"Add Doctor",
    bookAppoint:"Book Appoinment",
    fullName:"Full name",
    languages:"Languages",
    location:"Location",
    education:"Education",
    speciality:"Speciality",
    license:"License",
    dateOfB:"Date of Birth",
    contactRequest:"Contract request", 
    talk:"Let’s talk",
    aboutMe:"About me",
    rating:"Rating",
    reply:"Reply",
    publication:"Publication",
    readMore:"Read more",
  },
  patientsPage:{
    myPatients:"My patients",
    todays:"Todays",
    Consultation:"Consultation",
    Emergency:"Emergency",
    details:"View details",
    patientProfile:"Patient profile",
    registerDate:"Register Date",
    phone:"Phone",
    gender:"Gender",
    email:"Email", 
    bloodType:"Blood type",
    passport:"Passport",
    education:"Education",
    weight:"Weight",
    height:"Height",
    insurance:"Insurance",
    conditions:"Medical Condition",
    ehr:"EHR",
    generalInfo:"General info",
    created:"Created",
    update:"Update",
    fullName:"Full name",
    dateOfB:"Date of Birth",
    placeLived:"Places lived",
    maritalStatus:"Marital status",
    insuranse:"Insuranse",
    bloodGroup:"Blood group",
    bodyIndex:"Body mass index",
    groupOfH:"Group of health",
    pleaseContact:"Please, contact with your regular doctor and receive more correct info. After fill this form.",
    sport:"Sport activites ",
    rarely:"Rarely",
    badHabbits:"Bad habits",

    allergyReaction:"Allergy reaction",
    chronicDiseases:"Chronic diseases",
    regularity:"Regularity of medical consultations",
    save:"Save",
    pills:"Pills recipe",
  }

  },
  'ua': {
    welcomeMsg: 'Знайти лікаря, записатися на прийом, отримати консультацію онлайн в будь-якій точці світу.',
    joinBtn: 'Приєднатися',
    aboutUs:'про нас',
    aboutUsTitle: 'Doc2You - глобальна медична цифрова платформа для людей, які цінують своє здоров\'я та здоров\'я своїх близьких.',
    aboutUsDescription:"Doc2You дає Вам можливість знати нові тенденції в галузі медицини. Doc2You допомагає Вам стежити за своїм здоров'ям, використовуючи знання лікарів, фармацевтів, а також ознайомитися з найкращими практиками у сфері медицини. Doc2You - незалежний проєкт, головна цінність якого людина і її здоров'я. Вам потрібен лікар чи інформація, яка дозволить розв’язати проблему? Doc2You дасть Вам необхідні відповіді та допоможе Вам. Вам потрібно розширити медичну практику та знайти нових пацієнтів? Doc2You допоможе Вам розкрити Ваш професіоналізм. Doc2You – це цікаве місце для професійного спілкування з колегами та пацієнтами з усього світу. Doc2You дає Вам повний спектр необхідного функціоналу, який дозволить Вам максимально реалізувати свої професійні амбіції або задовольнити запити пацієнтів.",
    serviceTitle:"Наші сервіси",
    whatWeDo:"що ми робимо",
    getInTouch:"зв'язатися",
    getInTouchTitle:"Отримайте належне медичне обслуговування, запишіться на прийом онлайн",
    getInTouchDescription:"Є питання? Ми відповімо на всі ваші запитання та допоможемо у будь-яких навіть найскладніших випадках!",
    ourContacts:"Наші контакти",
    ourContactsTitle:"Онлайн консультації кваліфікованих лікарів",
    whatWeDoFirst:{
      id:1,
      svgSrc: 'assets/what-we-do/Vector.svg',
      imgSrc: 'assets/what-we-do/what-we-do_card-1.png',
      color: '#e8375f',
      title: 'Первинна допомога 24/7',
      description:"Незалежна Онлайн-платформа, яка надає пацієнтам можливість знаходити лікарів та організовувати з ними консультації в режимі on-line, у будь-якій точці планети та у будь-який час.",
    },
    whatWeDoSecond:{
      id:2,
      svgSrc: 'assets/what-we-do/what-we-do_card-block-mask.svg',
      imgSrc: 'assets/what-we-do/what-we-do_card-2.png',
      color: '#0F94BC',
      title: 'ЕМК Електронна медична карта',
      description:
        "Отримання доступу до своєї захищеної електронної медичної карти, історії хвороби, виписок, рецептів в особистому кабінеті.",
    },
    whatWeDoThird:{
      id:3,
      svgSrc: 'assets/what-we-do/what-we-do_card-block-mask.svg',
      imgSrc: 'assets/what-we-do/what-we-do_card-2.png',
      color: '#0F94BC',
      title: 'Лікар онлайн',
      description:
      "Платформа надає можливість лікарям створити свою онлайн медичну практику та задовольнити запити пацієнтів. А пацієнтам отримати кваліфіковану медичну консультацію у своєму гаджеті.",
    },
    addressTitle:"Адреса",
    address:"1013 Centre Road Suite 403-B Вілмінґтон, Сполучені Штати, DE 19805",
    emailTitle:"Пошта",
    phoneTitle:"Телефон",
    subscribe:"Підпишіться на нашу щомісячну розсилку",
    send:"Відправити",
    mainFirst:{
      title:"Знайдіть найкращого лікаря",
      description:"У нас працюють тільки найкращі досвідчені лікарі",
    },
    mainSecond:{
      title:"Гарантія здоров'я",
      description:"Ми гарантуємо якість обслуговування",
    },
    mainThird:{
      title:"Отримати консультацію",
      description:"Онлайн консультація в будь-який час",
    },
    mainFour:{
      title:"Призначайте зустрічі",
      description:"Запишіться на прийом до лікаря",
    },
    menu:{
      aboutUs:"Про нас",
      ourServices:"Сервіси",
      contacts:"Контакти",
    },
    internalMenu:{
      dashboard:"Дешборд",
      profile:"Профіль",
      ehr:"ЕМК Електронна медична картка",
      schedule:"Розклад",
      news:"Новини",
      messages:"Повідомлення",
      settings:"Налаштування",
      medicalR:"Медичний висновок",
      requestsOfD:"Запити лікарів",
      doctors:"Лікарі",
      patients:"Пацієнти",
      wallet:"Гаманець",
      services:"Сервіси",
      logOut:"Вихід",
    },
    profilePage: {
        userProfile:"Профіль користувача",
        general:"Загальний",
        fullName:"Повне ім'я",
        gender:"Стать",
        placeLived:"Місце проживання",
        speciality:"Спеціальність",
        contactInfo:"Контактна інформація",
        phoneNumber:"Номер телефону",
        approvedPhone:"Підтверджений номер телефону",
        change:"Змінити",
        educationWork:"Робота та Освіта",
        work:"Робота",
        education:"Освіта",
        changePass:"Змінити пароль",
        oldPass:"Старий пароль",
        newPass:"Новий",
    },
    ehrPage:{
      medicalReport:"Медичний висновок",
      downloadPdf:"Завантажити PDF",
      consultation:"Консультація",
      consultationDate:"Дата консультації",
      doctor:"Лікар",
      specialization:"Спеціалізація",
      meetingType:"Тип зустрічі",
      reportDate:"Дата висновку",
      lastUpdate:"Останнє оновлення",
      diacrisis:"Діакриз",
      comorbidities:"Супутні захворювання",
      previousDiseases:"Перенесені захворювання",
      chronicDiseases:"Хронічні захворювання",
      complaints:"Скарги",
      presentIllness:"Нинішня хвороба",
      objectivData:"Об'єктивні дані (необов'язково):",
      impression:"Висновок",
      procedure:"Процедура/контрольні дослідження",
      pharmaDrugs:"Фармацевтичні препарати",
      repeatedCons:"Повторна консультація",
      finish:"Закінчити",
    },
    shedulePage:{
      patientCard:"Картка пацієнта",
      height:"Зріст",
      weight:"Вага",
      bloodType:"Група крові",
      phone:"Телефон",
      cardNumber:"Номер картки",
      email:"Електронна пошта",
      medicalConditions:"Медичні захворювання",
      passport:"Паспорт",
      emergency:"Невідкладна допомога",
      consultation:"Консультація",
      firstTime:"Перша Консультація",
    },
    newsPage:{
      news:"Новини",
      newPost:"Новий пост",
      myPublication:"Моя публікація",
      comments:"Коментарі",
      likes:"Лайки",
      share:"Поділіться",
      writeComment:"Напишіть ваш коментар",
      discussions:"Обговорення",
      popularProfile:"Популярний профіль",
      viewProfile:"Перегляд профілю",
    },
    requestOfDPage:{
      welcome:"Ласкаво просимо,",
      welcomeh2:"На сьогодні у вас залишилося 7 пацієнтів і 4 запити!",
      todays:"На сьогодні",
      seeDetails:"Переглянути деталі",
      profile:"Профіль",
      psychiatrist:"Психіатр, психотерапевт",
      allPatients:"Всі пацієнти цього місяця",
      incomeThisM:"Доходи в цьому місяці",
      newAppointments:"Запис нових пацієнтів на прийом",
      todaysAppointments:"Сьогоднішня зустріч", 
      appointmentRequests:"Запити на прийом ",
      historyAppointments:"Історія прийомів",
      consultation:"Консультація",
      firstVisit:"Перший візит",
      emergency:"Невідкладна допомога",
      patientDetails:"Дані пацієнта",
      DateOfB:"Дата народження",
      gender:"Стать",
      weight:"Вага",
      height:"Зріст",
      lastAppointmentg:"Останній прийом",
      registerDate:"Дата реєстрації",
      chat:"Чат",
      ehr:"ЕМК Електронна медична картка",
    },
    doctorsPage:{
      add:"Додати Лікаря",
      bookAppoint:"Запис на прийом",
      fullName:"Повне ім'я",
      languages:"Мови",
      location:"Місцезнаходження",
      education:"Освіта",
      speciality:"Спеціальність",
      license:"Ліцензія",
      dateOfB:"Дата народження",
      contactRequest:"Запит на укладання контракту", 
      talk:"Поговоримо",
      aboutMe:"Про мене",
      rating:"Рейтинг",
      reply:"Відповідь",
      publication:"Публікація",
      readMore:"Читати далі",
    },
    patientsPage:{
      myPatients:"Мої пацієнти",
      todays:"Сьогодні",
      consultation:"Консультація",
      emergency:"Невідкладна допомога",
      details:"Детальніше",
      patientProfile:"Профіль пацієнта",
      registerDate:"Дата реєстрації",
      phone:"Телефон",
      gender:"Стать",
      email:"Електронна пошта", 
      bloodType:"Група крові",
      passport:"Паспорт",
      weight:"Вага",
      height:"Зріст",
      insurance:"Страховий поліс",
      conditions:"Стан здоров'я",
      ehr:"ЕМК Електронна медична картка",
      generalInfo:"Загальна інформація",
      created:"Створено",
      update:"Оновлення",
      fullName:"Повне ім'я",
      dateOfB:"Дата народження",
      placeLived:"Місця проживання",
      maritalStatus:"Сімейний стан",
      insuranse:"Страховий поліс",
      bloodGroup:"Група крові",
      bodyIndex:"Індекс маси тіла",
      groupOfH:"Група здоров'я",
      pleaseContact:"Будь ласка, зверніться до свого лікаря і отримайте більш точну інформацію. Після заповнення цієї форми.",
      sport:"Спортивна активність",
      rarely:"",
      badHabbits:"Шкідливі звички",

      allergyReaction:"Алергічні реакції",
      chronicDiseases:"Хронічні захворювання",
      regularity:"Регулярність медичних оглядів",
      save:"",
      pills:"Рецепт таблеток",
    }
  }
};

const i18n = new VueI18n({
  locale: 'en',
  messages
});

new Vue({
  router,
  store,
  render: h => h(App),
  i18n,
}).$mount('#app');
