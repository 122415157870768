import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'landing',
      component: () => import('./views/Landing.vue'),
    },
    {
      path: '/doctor-appointment',
      name: 'doctor-appointment',
      component: () => import('./views/DoctorAppointment.vue'),
    },
    {
      path: '/doctors',
      name: 'doctors',
      component: () => import('./views/Doctors.vue'),
    },
    {
      path: '/doctor/:id/doctor-profile',
      name: 'doctor-profile',
      component: () => import('./views/Doctor.vue'),
    },
    {
      path: '/user',
      name: 'user',
      component: () => import('./views/User.vue'),
    },
    {
      path: '/clients',
      name: 'clients',
      component: () => import('./views/Clients.vue'),
    },
    {
      path: '/client/:id/patient-profile',
      name: 'patient-profile',
      component: () => import('./views/PatientProfile.vue'),
    },
    {
      path: '/client/:id/ehr',
      name: 'patient-ehr',
      component: () => import('./views/PatientEhr.vue'),
    },
    {
      path: '/patient-medical-reports',
      name: 'patient-medical-reports',
      component: () => import('./views/PatientMedicalReports.vue'),
    },
    {
      path: '/medical-report-view/:id',
      name: 'medical-report-view',
      component: () => import('./views/MedicalReportInformation.vue'),
    },
    {
      path: '/medical-report/:id',
      name: 'medical-report',
      component: () => import('./views/MedicalReport.vue'),
    },
    {
      path: '/health-record',
      name: 'health-record',
      component: () => import('./views/ElectronicHealthRecord.vue'),
    },
    {
      path: '/patient-requests',
      name: 'patient-requests',
      component: () => import('./views/PatientRequests.vue'),
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('./views/Calendar.vue'),
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('./views/News.vue'),
    },
    {
      path: '/news-article/:id',
      name: 'news-article',
      component: () => import('./views/NewsArticle.vue'),
    },
    {
      path: '/publications',
      name: 'publications',
      component: () => import('./views/Publications.vue'),
    },
    {
      path: '/publication-article/:id',
      name: 'publication-article',
      component: () => import('./views/PublicationArticle.vue'),
    },
    {
      path: '/my-publications',
      name: 'my-publications',
      component: () => import('./views/MyPublications.vue'),
    },
    {
      path: '/my-publications/edit/:id',
      name: 'my-publications-edit',
      component: () => import('./views/EditPublication.vue'),
    },
    {
      path: '/new-publication',
      name: 'new-publication',
      component: () => import('./views/NewPublication.vue'),
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('./views/Admin.vue'),
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('./views/Dashboard.vue'),
    },
    // for test registration
    {
      path: '/registration-doctor',
      name: 'registration-doctor',
      component: () => import('./components/admin/doctor-registration/DoctorRegistrationPage.vue'),
    },
    {
      path: '/wallet',
      name: 'wallet',
      component: () => import('./views/Wallet.vue'),
    },
    {
      path: '/laboratory',
      name: 'laboratory',
      component: () => import('./views/Laboratory.vue'),
    },
    {
      path: '/lab-test',
      name: 'lab-test',
      component: () => import('./views/LabTest.vue'),
    },
    {
      path: '/order-lab-test',
      name: 'order-lab-test',
      component: () => import('./views/OrderLabTest.vue'),
    },
    {
      path: '/services',
      name: 'services',
      component: () => import('./views/Services.vue'),
    },
    {
      path: '/messages',
      name: 'messages',
      component: () => import('./views/Messages.vue'),
    },
    {
      path: '/video-meet',
      name: 'video-meet',
      component: () => import('./views/VideoMeet.vue'),
    },
    {
      path: '/visits',
      name: 'visits',
      component: () => import('./views/Visits.vue'),
    },
    {
      path: '/deactive',
      name: 'deactive',
      component: () => import('./views/Deactive.vue'),
    },
    {
      path: '/doctor-proffecional-profile',
      name: 'doctor-proffecional-profile',
      component: () => import('./views/DoctorProffecionalProfile.vue'),
    },
    //admin routes
    {
      path: '/logzkdieo/auth',
      name: 'adminAuth',
      component: () => import('./views/admin-panel/Auth.vue'),
    },
    {
      path: '/logzkdieo/dashboard',
      name: 'adminDashboard',
      component: () => import('./views/admin-panel/Dashboard.vue'),
    },
    {
      path: '/logzkdieo/doctors',
      name: 'adminDoctorsList',
      component: () => import('./views/admin-panel/DoctorsList.vue'),
    },
    {
      path: '/logzkdieo/doctors/:id/information',
      name: 'adminDoctorInformation',
      component: () => import('./views/admin-panel/DoctorSingle.vue'),
    },
    {
      path: '/logzkdieo/doctors-requests',
      name: 'adminDoctorsRequests',
      component: () => import('./views/admin-panel/DoctorsRegistrationRequests.vue'),
    },
    {
      path: '/logzkdieo/news',
      name: 'admin-news',
      component: () => import('./views/admin-panel/NewsList.vue'),
    },
    {
      path: '/logzkdieo/news/create',
      name: 'admin-news-create',
      component: () => import('./views/admin-panel/CreateNews.vue'),
    },
    {
      path: '/logzkdieo/news/:id/edit',
      name: 'admin-news-edit',
      component: () => import('./views/admin-panel/EditNews.vue'),
    },
  ],
});
